.borrow-limit-section{
 height: 80px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 color: var(--text);
}

.borrow-limit-header{
    font-size: 1rem;
    color: var(--secondaryText);
}

.borrow-limit-item{
    display: flex;
    justify-content: space-between;
    color: var(--secondaryText);
    font-size: .9rem;
}

.borrow-limit-item .borrow-limit-item-details span{
    padding: 0 3px;
}