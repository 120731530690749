.dialog-section{
    background-color: var(--dialogSectionBackground);
    color: var(--secondaryText);
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.dialog-section-no-top-gap{
    padding-top: 0;
}

.dialog-section-no-bottom-gap{
    padding-bottom: 0;
}


.dialog-section-content{
    
    display: flex;
    height: 25px;
    justify-content: space-between;
}

.dialog-section .dialog-section-content .fill{
    flex:1;
    padding-left: 12px;
    text-align: left;
    line-height: 25px;
}

.dialog-section .logo-holder{
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-section-title{
    display: none;
}

.dialog-section-content .dialog-section-content-header{
    line-height: 25px;
}

.dialog-section-content .dialog-section-content-value{
    line-height: 25px;
    display: flex;
}

.dialog-section-content-details{
    display: flex;
    height: 5px;
    justify-content: space-between;
    font-size: 0.85rem;
}

.dialog-section-content .dialog-section-content-header-details{
    line-height: 0px;
}

.dialog-section-content-value-details{
    line-height: 0px;
    
}

.dialog-section-content .dialog-section-content-value .dialog-section-arrow{
    
    position: relative;
    padding: 0 5px;
    display: none;
    
}

.dialog-section-content .dialog-section-content-value .dialog-section-arrow-active{
    display: initial;
}

.dialog-section-content .dialog-section-content-value .dialog-section-arrow svg{
    transform: rotate(270deg);
}


