/* .star{
    padding: 0;
    width: 15px;
    height: 15px;
    padding-right: 3px;
    margin-right: 2px;
    margin-bottom: 2px;
} */

.a {
    color: #fff;
}
.a:hover {
    color: #00f;
}
.star-bpro-icons img{
    width:16px;
    height: 16px;
    padding-right: 3px;
}