.address-button {
    border: 1px solid var(--buttonColor);
    color: var(--navText);
    margin: 0 5px;
    display: flex;
    height: 40px;
    width: 170px;
    border-radius: 20px;
    cursor: pointer;
    padding: 0 0px;
    transition: background-color 0.2s ease-out;
    position: relative;
    align-items: center;
    justify-content: center;
}

.address-button:hover{
    background-color: var(--buttonColor);
    color:var(--buttonHover2);
}

.address-button .address{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.address-button .address .arrow{
    font-size: 0.7rem;
    /* color: var(--buttonColor); */
}

.wallets {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    justify-content: space-around;
    gap: 20px;
}

.wallets .wallet-item{
    display: flex;
    align-items: center;
    padding: 10px 10px;
    gap: 15px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 15px;
    font-size: 1.1rem;
    transition: all 200ms ease-in;
}

.wallets .wallet-item .wallet-item-icon img{
    width: 30px;
    height: 30px;
}

.wallets .wallet-item:hover{
    border: 1px solid var(--buttonBorder);
    opacity: 0.9;
  }
  
  .wallets .wallet-item:active{
    background-color: var(--buttonHover);
    color: var(--text);
  }

  .modal-error{
      font-size: 1.1rem;
      min-height: 70px;
      display: flex;
      align-items: center;
  }

  .modal-error-switch{
      color: var(--buttonHover);
      cursor: pointer;
  }

  .modal-error-switch:hover{
      text-decoration: underline;
  }