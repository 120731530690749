
html{
  font-size: 14px;
  scrollbar-color: #333 #bebebe;
  scrollbar-width: thin;
}

.App {
  min-height: 100vh;
  width: 100%;
  min-width: 320px;
  height: 100%;
  position: relative;
  text-align: center;
  background-color: var(--background);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.Toastify__toast-body{
  white-space: pre-line;
}