.switch{
    height: 10px;
    width: 45px;
    border-radius: 10px;
    background-color: #b8b8b8;
    position: relative;
    transition: all 0.5s ease-out;
}

.switch .switch-button{
    
    height: 20px;
    width: 20px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(89,89,89,0.5); 
    box-shadow: 0px 0px 10px 1px rgba(89,89,89,0.5);
    background-color: #fff;
    position: absolute;
    top:-5px;
    transition: all 0.2s ease-out;
}

.switch-button-enabled .switch-button:hover{
    -webkit-box-shadow: 0px 0px 10px 3px rgba(89,89,89,0.5); 
    box-shadow: 0px 0px 10px 3px rgba(89,89,89,0.5);
}



.switch-checked{
    transition: all 0.5s ease-out;
    background-color: var(--buttonHover);
}

.switch-checked .switch-button{
    transition: all 0.5s ease-out;
    right: 0;
}

.switch-button-disable{
    opacity: .6;
}
