.overlay{
    overflow: hidden;

}

.overlay-left{
    background-color: #131313;
    position: fixed;
    top:0;
    left:0;
    right:50%;
    bottom:0;
    z-index: 100000;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.overlay-left-collapse{
    transition: all 1.5s ease-in 1s;
    right: 100%;
}

.overlay-left .logo{
    position: absolute;
}

.overlay-right{
    background-color: #131313;
    position: fixed;
    top:0;
    left:50%;
    right:0;
    bottom:0;
    z-index: 100000;
    overflow: hidden;
    display: flex;
    align-items: center;
    
}

.overlay-right-collapse{
    transition: all 1.5s ease-in 1s;
    left: 100%;
}

.overlay-right .logo{
    position: absolute;
}