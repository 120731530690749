.main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
    flex-wrap: wrap;
}

.lendly-item{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: var(--logoBackground);
    border: 1px solid var(--logoBorder);
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.lendly-item:hover{
    opacity: 0.85;
    background-color: #f1f1f1;
}