.navbar-button{
    border: 1px solid var(--text);
    border-radius: 10px;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-out;
}

/* .navbar-button:hover{
    background-color: var(--navbarButtonHover);
} */

.navbar-button .bar{
    background-color: var(--text);
    width: 25px;
    height: 2px;
    border: 1px solid var(--text);
    border-radius: 3px;
    display: block;
    position: absolute;
    transition: all .2s ease-out;
}

.navbar-button .bar1{
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
}

.navbar-button .bar2{
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.navbar-button .bar3{
    left: 50%;
    top: 70%;
    transform: translate(-50%, -70%);
}

.navbar-button-clicked .bar1{
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.navbar-button-clicked .bar2{
    opacity: 0;
}

.navbar-button-clicked .bar3{
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

