@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*, *::after, *::before{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --navBackground: #111111;
  --background: #f9fafb;
  --logoBackground: #f9fafb;
  --sectionBackground: #101010;
  --secondaryBackground: #f9fafb;
  --boxBackground: #fff;
  --text: #FFF;
  --navText: #FFF;
  --secondaryText: #000;
  --bottomShadow: 0px 1px 10px 1px rgba(17, 162, 234, 0.5);
  --overlayBackground: rgba(0, 0, 0, 0.6);
  --buttonColor: #10337a;
  --buttonHover: #2853ff;
  --buttonHover2: #f0f0f0;
  --textShadow: 1px 1px 1px rgba(17, 162, 234, .2);
  --spinnerColor: #f0f0f0;
  --boxShadow: 0px 0px 15px -1px rgba(97, 97, 97, 0.5);
  --titleShadow:0px 3px 15px -3px rgba(97, 97, 97, 0.5);
  --containerTitleShadow:0px 5px 5px -1px rgba(0,0,0,0.41);
  --tableHeaderShadow:0px 2px 5px -1px rgba(0,0,0,0.41);
  --tableRowShadow:0px 0px 3px -1px rgba(0,0,0,0.41);
  --textboxPlaceholder:rgba(51, 51, 51, .5);
  --textPositive: #006d5e;
  --dialogSectionBackground:#f9f9f9;
  --borderBottom: rgba(10, 6, 6, 0.05);
  --grayText: #727A89;
  --showMore: #092665;
  --navbarButtonHover: #444;
  --borderBottom2: rgba(10, 6, 6, 0.5);
  --buttonBorder: #101010;
  --buttonActiveShadow: 0px 0px 10px 0px rgba(10,22,30,0.9);
  --buttonActiveBackground: #6b6b6b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-theme{
  --navBackground: #101010;
  --background: #101010;
  --logoBackground: hsla(210, 20%, 98%, 0.9);
  --sectionBackground: #101010;
  --secondaryBackground: #101010;
  --boxBackground: #000;
  --text: #EEE;
  --secondaryText: #fff;
  --bottomShadow: 0px 1px 10px 1px rgba(230, 230, 230, 0.5);
  --overlayBackground: rgba(250, 250, 250, 0.2);
  --boxShadow: 0px 0px 15px -1px rgba(230, 230, 230, 0.5);
  --titleShadow:0px 3px 15px -3px rgba(230, 230, 230, 0.5);
  --containerTitleShadow:0px 5px 5px -1px rgba(255,255,255,0.41);
  --tableHeaderShadow:0px 2px 5px -1px rgba(255,255,255,0.41);
  --tableRowShadow:0px 0px 3px -1px rgba(255,255,255,0.41);
  --textboxPlaceholder:rgb(238, 238, 238,.5);
  --textPositive: #00ffdd;
  --dialogSectionBackground:#333;
  --borderBottom: rgba(255, 255, 255, 0.12);
  --showMore: #fff;
  --themeSwitchBackground: #f9fafb;
  --borderBottom2: rgba(255, 255, 255, 0.5);
  --buttonBorder: #EEE;
  --buttonActiveShadow: 0px 0px 10px 0px rgba(248,259,260,0.9);
}


.weve{
  --sectionBackground: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(0,193,214,1) 0%, rgba(0,254,218,1) 100%);
  --navBackground: rgba(0,254,218,1);
  --navText: #101010;
}