.main{
    color: var(--secondaryText);
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.switch-network-link{
    text-decoration: underline;
    color: var(--buttonHover);
    cursor: pointer;
}