.dialog{
    display: none;
    position: fixed;
    top:0vh;
    width:100%;
    height: 100%;
    z-index: 5000;
    left:0;
    margin: 0;
    padding: 10px;
    overflow: hidden;
}

.dialog .dialog-background{
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: var(--overlayBackground);
    top:0;
    left: 0;
}

.open-dialog{
    display: block;
}

.supply-box{
    min-width: 305px;
    max-width: 400px;
    max-height: 99.9%;
    border-radius: 10px;
    padding:1rem;
    position: relative;
    margin: auto;
    top:50%;
    transform: translateY(-50%);
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.supply-box-expand{
    max-height: 613px;
}

.supply-box .dialog-close  {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}
/* 
.supply-box .dialog-close:hover{
    background-color: var(--buttonColor);
    color: var(--buttonHover2);
} */

.supply-box .dialog-title{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
    color: var(--secondaryText);
    font-size:1.2rem;
}

.native-asset-amount {
    font-size: 0.8rem;
    position: relative;
    color: var(--secondaryText);
}

.native-asset-amount span {
    position: absolute;
    bottom: -26px;
    left: 0;
}

.amount-select {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    align-items: end;
    width: 150px;
}

.amount-select div {
    margin-bottom: unset !important;
    text-decoration: underline;
    cursor: pointer;
}

.native-asset-amount .amount-select {
    position: absolute;
    bottom: -26px;
    right: 0;
}

.input-button-group {
    display: grid;
    grid-template-columns: 69% 29%;
    grid-gap: 2%;
    margin-top: 35px;
}

.input-button-group .textbox .validation{
    bottom:-15px;
}