.tab{
    overflow: hidden;
    position: relative;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /*position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid red;*/
    
}

.tab-header{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.tab-header-item{
    line-height: 50px;
    width:50%;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select: none;
    color: var(--secondaryText);
}

.tab-content{
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 5px;
}

.tab-content-item{
    padding: 0 5px;
    position: relative;
    top:0;
    display: block;
    height: 0;
    overflow:hidden;
    transform: scale(0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out,
                transform 0.2s ease-in-out;
    
    /*
    padding-right: 10px;
    top:-200%;
    position: absolute;
    height: 100%;
    width: calc(100% - 20px);
    overflow-y: auto;
    transform: scale(0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out,
                transform 0.2s ease-in-out;
    */
}

.tab-content-item div:not(:last-child){
    margin-bottom: 5px;
}

.tab-content .active{
    height: 100%;
    top:0;
    opacity: 1;
    transform: scale(1);
    overflow-y: auto;
}

.tab-header .active{
    color: var(--buttonHover);
    transition: all 0.2s ease-in-out;
}

.indicator{
    position: absolute;
    left:0;
    bottom: 5px;
    height: 2px;
    background-color: var(--buttonHover);
    transition: left 0.2s ease-in-out; 
}